<template>
<div>
<v-container>
<div class="news_video dirr">
    <div class="news_con d-none d-sm-flex">
        <div class="impoNews" v-if="impoNews_skeleton">
            <v-layout row>
                <v-flex sm12 xs12 md8 lg8 style="padding:0 10px;">
                    <v-skeleton-loader  width="800" min-width="800" type="image"></v-skeleton-loader>
                    <v-skeleton-loader  width="800" min-width="800" type="image"></v-skeleton-loader>
                    <v-skeleton-loader  width="800" min-width="800" type="card"></v-skeleton-loader>
                </v-flex>
                <v-flex sm12 xs12 md4 lg4 style="padding:0 10px;">
                    <v-skeleton-loader  class="mx-auto" width="350" min-width="350" max-width="350" type="paragraph, paragraph, paragraph, sentences, paragraph, sentences, sentences, paragraph, sentences, actions"></v-skeleton-loader>
                </v-flex>
            </v-layout>
        </div>
        <div class="impoNews" v-else>
            <div class="title" style="padding:0 20px 20px 20px;">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <p class="tl">{{ $t("Video_Reports") }}</p>
            </div>
            <div class="items">
                <router-link :to="'/new/'+i.id+'?lang='+$i18n.locale" v-for="i in impoNews.slice(0,1)" :key="i" class="item Fitem">
                    <v-layout row>
                        <v-flex sm12 xs12 md8 lg8 style="padding:0 10px;">
                            <!-- <img :src="'https://alkafeel.net/news/mcroped96/625/' + impoNews[0].image" :alt="impoNews[0].title" /> -->
                            <div class="img">
                                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                    :src="'https://alkafeel.net/news/mcroped96/800/'+i.image" style="border-radius: 4px;">
                                </v-img>
                                <div class="icon">
                                    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="36" cy="36" r="36" fill="#B1BD52" fill-opacity="0.8"/>
                                    <path d="M52.5 35.134C53.1667 35.5189 53.1667 36.4811 52.5 36.866L28.5 50.7224C27.8333 51.1073 27 50.6262 27 49.8564L27 22.1436C27 21.3738 27.8333 20.8927 28.5 21.2776L52.5 35.134Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                        </v-flex>
                        <v-flex sm12 xs12 md4 lg4 style="padding:0 10px;">
                            <div :class="'paregs ' + $store.state.direction">
                                <p class="tl">{{i.title}}</p>
                                <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" v-html="removebbcode(i.text) + ' ...'"></p>
                                <div class="social d-none d-sm-flex">
                                    <a :href="'https://telegram.me/share/?url=https://alkafeel.net/news/index?id='+i.id+'&lang='+$i18n.locale" target="_blank" class="telegram">
                                        <img :src="$vuetify.theme.dark ? '/assets/img/news/Telegram_white.svg' : '/assets/img/news/Telegram_dark.svg'" />
                                    </a>
                                    <a :href="'https://twitter.com/intent/tweet?text=https://alkafeel.net/news/index?id='+i.id+'&lang='+$i18n.locale" target="_blank" class="twitter">
                                        <img :src="$vuetify.theme.dark ? '/assets/img/news/twitter_white.svg' : '/assets/img/news/twitter_dark.svg'" />
                                    </a>
                                    <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://alkafeel.net/news/index?id='+i.id+'&lang='+$i18n.locale" target="_blank" class="facebook">
                                        <img :src="$vuetify.theme.dark ? '/assets/img/news/Facebook_white.svg' : '/assets/img/news/Facebook_dark.svg'" />
                                    </a>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </router-link>
                <div class="items">
                    <v-layout row>
                        <v-flex sm4 xs6 md4 lg4  v-for="i in impoNews.slice(1,4)" :key="i" style="padding:0 10px;">
                        <router-link :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item Oitem">
                            <div class="img">
                                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                    :src="'https://alkafeel.net/news/mcroped96/625/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                                </v-img>
                                <div class="icon">
                                    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="32" cy="32" r="32" fill="#B1BD52" fill-opacity="0.8"/>
                                    <path d="M46.5 31.134C47.1667 31.5189 47.1667 32.4811 46.5 32.866L25.5 44.9904C24.8333 45.3753 24 44.8942 24 44.1244L24 19.8756C24 19.1058 24.8333 18.6247 25.5 19.0096L46.5 31.134Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <p class="tl">{{i.title | subStrimpoNewsTitle}}</p>
                        </router-link>
                        </v-flex>
                    </v-layout>
                </div>
            </div>
        </div>
        <div class="reports">
            <div class="items">
                <v-layout row>
                    <v-flex sm12 xs12 md6 lg6 v-for="i in MoreRes" :key="i" style="padding:0 10px;">
                        <router-link :to="'/new/' + i.id + '?&lang=' +$i18n.locale" class="item" >
                            <div class="img">
                                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                    :src="'https://alkafeel.net/news/mcroped96/260/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                                </v-img>
                                <div class="icon">
                                    <svg width="54" height="55" viewBox="0 0 54 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8"/>
                                    <path d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z" fill="white"/>
                                    </svg>
                                </div>
                            </div>
                            <div :class="'paregs ' + $store.state.direction">
                                <p class="tl ta">{{i.title}}</p>
                                <div class="details">
                                    <div class="division" v-if="$i18n.locale=='ar'">
                                        <span>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.46478 0.531427L4.263 3.73291L4.79605 4.26591L7.99783 1.06443L7.46478 0.531427Z" fill="#B1BD52"/>
                                            <path d="M7.46754 2.66307L5.33012 4.80029L5.86317 5.33329L8.00059 3.19607L7.46754 2.66307Z" fill="#B1BD52"/>
                                            <path d="M10.6656 3.7336L10.1326 4.2666L10.6656 4.7996L11.1987 4.2666L10.6656 3.7336Z" fill="#B1BD52"/>
                                            <path d="M8.53349 3.72975L6.3978 5.86523L6.93085 6.39823L9.06654 4.26275L8.53349 3.72975Z" fill="#B1BD52"/>
                                            <path d="M8.5345 5.8646L7.46492 6.93408L7.99797 7.46708L9.06755 6.3976L8.5345 5.8646Z" fill="#B1BD52"/>
                                            <path d="M7.4676 2.66459L10.1329 5.32959L10.6659 4.79659L8.00065 2.13159L7.4676 2.66459Z" fill="#B1BD52"/>
                                            <path d="M9.49979 2.56585L11.2 4.26587L11.733 3.73287L10.0328 2.03285L9.49979 2.56585Z" fill="#B1BD52"/>
                                            <path d="M7.46785 4.79749L9.06699 6.39648L9.60004 5.86348L8.0009 4.26448L7.46785 4.79749Z" fill="#B1BD52"/>
                                            <path d="M4.26789 3.73328L5.86703 5.33228L6.40008 4.79928L4.80094 3.20028L4.26789 3.73328Z" fill="#B1BD52"/>
                                            <path d="M7.46758 0.533244L8.96744 2.03296L9.50049 1.49996L8.00063 0.000243692L7.46758 0.533244Z" fill="#B1BD52"/>
                                            <path d="M0.530459 8.53314L3.73224 11.7346L4.26529 11.2016L1.06351 8.00014L0.530459 8.53314Z" fill="#B1BD52"/>
                                            <path d="M2.6625 8.52879L4.79992 10.666L5.33297 10.133L3.19555 7.99579L2.6625 8.52879Z" fill="#B1BD52"/>
                                            <path d="M3.73345 5.33224L4.26649 5.86523L4.79954 5.33224L4.26649 4.79924L3.73345 5.33224Z" fill="#B1BD52"/>
                                            <path d="M3.73178 7.46144L5.86745 9.59692L6.4005 9.06392L4.26482 6.92844L3.73178 7.46144Z" fill="#B1BD52"/>
                                            <path d="M5.86443 7.46201L6.93401 8.53149L7.46706 7.9985L6.39748 6.92901L5.86443 7.46201Z" fill="#B1BD52"/>
                                            <path d="M2.66334 8.52901L5.32858 5.86401L4.79553 5.33101L2.13029 7.99601L2.66334 8.52901Z" fill="#B1BD52"/>
                                            <path d="M2.56673 6.49763L4.26691 4.79761L3.73387 4.26461L2.03368 5.96463L2.56673 6.49763Z" fill="#B1BD52"/>
                                            <path d="M4.79683 8.52795L6.39598 6.92896L5.86293 6.39596L4.26378 7.99495L4.79683 8.52795Z" fill="#B1BD52"/>
                                            <path d="M3.73212 11.7312L5.23197 10.2314L4.69892 9.69845L3.19907 11.1982L3.73212 11.7312Z" fill="#B1BD52"/>
                                            <path d="M0.53299 8.53194L2.03284 7.03223L1.4998 6.49923L-5.83793e-05 7.99894L0.53299 8.53194Z" fill="#B1BD52"/>
                                            <path d="M8.53245 15.4695L11.7342 12.2681L11.2012 11.7351L7.9994 14.9365L8.53245 15.4695Z" fill="#B1BD52"/>
                                            <path d="M8.53011 13.3369L10.6675 11.1997L10.1345 10.6667L7.99706 12.8039L8.53011 13.3369Z" fill="#B1BD52"/>
                                            <path d="M5.33399 12.2669L5.86703 11.7339L5.33399 11.2009L4.80094 11.7339L5.33399 12.2669Z" fill="#B1BD52"/>
                                            <path d="M7.46362 12.2695L9.5993 10.134L9.06625 9.60103L6.93057 11.7365L7.46362 12.2695Z" fill="#B1BD52"/>
                                            <path d="M7.46427 10.1371L8.53385 9.06763L8.0008 8.53463L6.93122 9.60411L7.46427 10.1371Z" fill="#B1BD52"/>
                                            <path d="M8.53033 13.3349L5.86508 10.6699L5.33203 11.2029L7.99728 13.8679L8.53033 13.3349Z" fill="#B1BD52"/>
                                            <path d="M6.49913 13.4346L4.79894 11.7346L4.26589 12.2676L5.96608 13.9676L6.49913 13.4346Z" fill="#B1BD52"/>
                                            <path d="M8.52897 11.203L6.92982 9.604L6.39677 10.137L7.99592 11.736L8.52897 11.203Z" fill="#B1BD52"/>
                                            <path d="M11.732 12.2673L10.2321 10.7676L9.69908 11.3006L11.1989 12.8003L11.732 12.2673Z" fill="#B1BD52"/>
                                            <path d="M8.53203 15.4672L7.03217 13.9675L6.49912 14.5005L7.99898 16.0002L8.53203 15.4672Z" fill="#B1BD52"/>
                                            <path d="M15.4692 7.4676L12.2674 4.26611L11.7343 4.79911L14.9361 8.0006L15.4692 7.4676Z" fill="#B1BD52"/>
                                            <path d="M13.3361 7.47243L11.1987 5.33521L10.6657 5.86821L12.8031 8.00543L13.3361 7.47243Z" fill="#B1BD52"/>
                                            <path d="M12.2653 10.6673L11.7323 10.1343L11.1992 10.6673L11.7323 11.2003L12.2653 10.6673Z" fill="#B1BD52"/>
                                            <path d="M12.269 8.53562L10.1333 6.40015L9.60022 6.93314L11.7359 9.06862L12.269 8.53562Z" fill="#B1BD52"/>
                                            <path d="M10.1348 8.53604L9.06518 7.46655L8.53213 7.99955L9.60171 9.06904L10.1348 8.53604Z" fill="#B1BD52"/>
                                            <path d="M13.3352 7.47123L10.6699 10.1362L11.203 10.6692L13.8682 8.00423L13.3352 7.47123Z" fill="#B1BD52"/>
                                            <path d="M13.434 9.50187L11.7338 11.2019L12.2669 11.7349L13.967 10.0349L13.434 9.50187Z" fill="#B1BD52"/>
                                            <path d="M11.2031 7.4718L9.60391 9.0708L10.137 9.6038L11.7361 8.0048L11.2031 7.4718Z" fill="#B1BD52"/>
                                            <path d="M12.2658 4.27031L10.766 5.77002L11.299 6.30302L12.7989 4.80331L12.2658 4.27031Z" fill="#B1BD52"/>
                                            <path d="M15.4669 7.47001L13.967 8.96973L14.5001 9.50273L15.9999 8.00301L15.4669 7.47001Z" fill="#B1BD52"/>
                                            <path d="M8.53264 5.8665L7.9996 5.3335L6.93524 6.39775L7.46829 6.93075L8.53264 5.8665Z" fill="white"/>
                                            <path d="M9.05321 9.59086L8.52016 9.05786L7.4558 10.1221L7.98885 10.6551L9.05321 9.59086Z" fill="white"/>
                                            <path d="M6.93395 8.5314L5.86437 7.46191L5.33132 7.99491L6.40091 9.06439L6.93395 8.5314Z" fill="white"/>
                                            <path d="M10.6693 8.00308L9.59972 6.93359L9.06667 7.46659L10.1362 8.53607L10.6693 8.00308Z" fill="white"/>
                                            </svg>
                                        </span>
                                        <p class="tl">{{i.cat_title}}</p>
                                    </div>
                                    <div class="date">
                                        <span><img :src="$vuetify.theme.dark ? '/assets/img/date_small_white.svg' : '/assets/img/date_small_dark.svg'" /></span>
                                        <p class="tl">{{ i.updated|FormatDate }}</p>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                    </v-flex>
                </v-layout>
            </div>
            <v-btn v-if="getMorenews" width="150" height="40" class="ma-2 tl" style="align-self:center !important;" :loading="loading" color="rgba(177, 189, 82, 1)" @click="ShowMore()">
                <p class="getMorenews alistSub tl">{{ $t('view_more') }}</p>
            </v-btn>
        </div>
    </div>
    <div class="mobile_con d-flex d-sm-none">
        <div class="title" style="padding:0 20px 20px 20px;">
            <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
            <p class="tl">{{ $t("Video_Reports") }}</p>
        </div>
        <!-- <router-link :to="'/new/'+i.id+'?lang='+$i18n.locale" v-for="i in MobRes.slice(0,1)" :key="i" class="item Fitem">
            <div class="img">
                <v-img height="100%" :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                    :src="'https://alkafeel.net/news/mcroped96/800/'+i.image" style="border-radius: 4px;">
                </v-img>
                <div class="icon">
                    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="36" r="36" fill="#B1BD52" fill-opacity="0.8"/>
                    <path d="M52.5 35.134C53.1667 35.5189 53.1667 36.4811 52.5 36.866L28.5 50.7224C27.8333 51.1073 27 50.6262 27 49.8564L27 22.1436C27 21.3738 27.8333 20.8927 28.5 21.2776L52.5 35.134Z" fill="white"/>
                    </svg>
                </div>
                <p class="tl ta">{{i.title}}</p>
            </div>
        </router-link> -->
        <router-link v-for="i in MobRes" :key="i" :to="'/new/'+i.id+'?lang='+$i18n.locale" class="item Oitem">
            <div class="img">
                <v-img :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                    :src="'https://alkafeel.net/news/mcroped96/625/'+i.image" style="height: 100%; width: 100%; border-radius: 4px;">
                </v-img>
                <div class="icon">
                    <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="36" cy="36" r="36" fill="#B1BD52" fill-opacity="0.8"/>
                    <path d="M52.5 35.134C53.1667 35.5189 53.1667 36.4811 52.5 36.866L28.5 50.7224C27.8333 51.1073 27 50.6262 27 49.8564L27 22.1436C27 21.3738 27.8333 20.8927 28.5 21.2776L52.5 35.134Z" fill="white"/>
                    </svg>
                </div>
            </div>
            <p class="tl ta">{{i.title}}</p>
        </router-link>
        <v-btn v-if="getMorenews" width="150" height="40" class="ma-2 tl" style="align-self:center !important;" :loading="loading" color="rgba(177, 189, 82, 1)" @click="ShowMore()">
            <p class="alistSub tl">{{ $t('view_more') }}</p>
        </v-btn>
    </div>
</div>

<news-search></news-search>

</v-container>
</div>
</template>

<script>
import searchVue from './search.vue';
// import InfiniteLoading from 'vue-infinite-loading';
export default {
    data() {
        return {
            impoNews : [],
            MoreRes : [],
            MobRes : [],
            impoNews_skeleton : true,
            NewsPage : 1,
            getMorenews : true,
            loader: null,
            loading: false,
            swiperOptions2: {
                slidesPerView: 'auto',
                spaceBetween: 30,
                freeMode: true,
                keyboard: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            },
            offsetTop: 0,
            axios_load: false
        }
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 2000)
            this.loader = null
        },
        offsetTop (val) {
            this.callbackFunc()
        }
    },
    components: {
        'news-search': searchVue
    },
    computed: {},
    mounted() {
        document.title =this.$t("Video_Reports"); 
    },
    created () {
        this.getNews ();
        if (! this.isMobileDevice()) {
            window.addEventListener('scroll', this.onScroll);
            }
    },
    methods:{
        onScroll (e) {
            setTimeout(() => {
                if (!this.axios_load) {this.offsetTop = window.pageYOffset || document.documentElement.scrollTop;}
            }, 700);
        },
        isElementInViewport(el) {
            var rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        },
        callbackFunc() {
            let el = document.querySelector('.getMorenews');
            if (this.isElementInViewport(el)) {this.ShowMore();}
        },
        getNews () {
            this.$axios.get("articles/GetVideosNews/8?page=" + this.NewsPage, {
            })
            .then(res => {
                this.NewsPage ++;
                this.impoNews = res.data.data;
                this.MoreRes = this.MoreRes.concat(this.impoNews.slice(4,8));
                this.MobRes = res.data.data;
                setTimeout(() => this.impoNews_skeleton = false, 500);
                //console.log(res.data);
                this.ShowMore ();
            })
            .catch(() => {
            });
        },
        ShowMore () {
            //console.log('ytyt');return;
            this.axios_load = true;
            this.loader = 'loading';
            this.$axios.get("articles/GetVideosNews/8?page=" + this.NewsPage, {})
            .then(res => {
                //console.log("articles/GetVideosNews/8?page=" + this.NewsPage,);
                this.axios_load = false;
                this.NewsPage ++;
                this.MoreRes = this.MoreRes.concat(res.data.data);
                this.MobRes = this.MobRes.concat(res.data.data);
                //this.NewsRes2 = res.data[0];
                if (res.data.data.length < 8) {this.getMorenews = false;}
                //setTimeout(() => this.lastNews_skeleton = false, 500);
                //console.log(res.data);
            })
            .catch(() => {
            });
        },   
        isMobileDevice() {
            var check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
                    .test(a) ||
                    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
                    .test(a.substr(0, 4))) check = true;
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        },
        removebbcode (x) {
            if (x) {return this.stripHTML(x.replace(/\[\/?[^\]]*\]/g, ''))}
        },
        stripHTML (value) {
            const div = document.createElement('div')
            div.innerHTML = value
            const text = div.textContent || div.innerText || ''
            return text
        }
    },
    filters: {
        subStrArtTitle: function (string) {if (string != null) {return string.substring(0, 24) + '...';}},
        subStrlastNewsitle: function (string) {if (string != null) {return string.substring(0, 130) + '...';}},
        subStrimpoNewsTitle: function (string) {if (string != null) {return string.substring(0, 85) + '...';}},
        FormatDate: function (unixTime='1648050625') {
            let d = new Date(unixTime*1000);
            let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
            let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
            let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
            return (`${da}-${mo}-${ye}`);
        },
    },
}
</script>